import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby'
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';

const TagsContainer = styled.div`
  font-size: 12px;
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.link};
    &:hover {
      color: ${props => props.theme.colors.linkHover};
      text-decoration: underline;
    }
  }
`;

const TagBlock = ({ tags }) => (
    <TagsContainer>
        {
            tags && tags.map((tag, idx) => {
                const lowerTag = tag.toLowerCase()
                if (idx === 0) {
                    return (
                        <>
                            Tagged:&nbsp;
                            <Link key={tag} to={`/tags/${kebabCase(lowerTag)}`}>
                            <span>{tag}</span>
                            </Link>
                        </>
                    );
                } else {
                    return (
                        <>
                            ,&nbsp;
                            <Link key={tag} to={`/tags/${kebabCase(lowerTag)}`}>
                            <span>{tag}</span>
                            </Link>
                        </>
                    );
                }
            })
        }
    </TagsContainer>
);

export default TagBlock;

TagBlock.propTypes = {
    tags: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.bool,
    ]),
  };
  
  TagBlock.defaultProps = {
    tags: false
  };