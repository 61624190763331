import React from 'react';
import styled from '@emotion/styled';
import { SocialIcon } from 'react-social-icons';
import theme from '../../config/theme';
import PropTypes from 'prop-types';

const Wrapper = styled.footer`
  position: relative;
  bottom: 0;
  box-shadow: ${props => props.theme.shadow.footer};
  background: ${props => props.theme.colors.background.medium};
  font-family: ${props => props.theme.fontFamily.body};
  font-weight: 500;
`;

const SocialFooter = styled.section`
  background: ${props => props.theme.colors.background.dark};
  padding: 0 1.875rem 1.25rem;
`;

const LegalFooter = styled.section`
  background: ${props => props.theme.colors.background.dark};
  margin: 0;
  padding: 0 0.5rem 2rem;
  text-align: center;
  color: ${props => props.theme.colors.white.light};
  font-size: 0.675em;
`;

const SocialList = styled.ul`
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: center;
  border-top: 1px #777 solid; 
  padding-top: 1.25rem;
  margin-bottom: 0;
  svg.social-svg {
      &:hover *.social-svg-icon {
        fill: ${props => props.theme.colors.linkHover} !important;
      }
  }
  li {
    margin: 0.5rem;
    font-size: 1.25rem;
  }
`;

const Footer = ({ siteMetadata }) => (
  <Wrapper>
    <SocialFooter>
      <SocialList>
          {siteMetadata.social.facebook && (
              <li><SocialIcon url={`https://www.facebook.com/${siteMetadata.social.facebook}/`} fgColor={theme.colors.link} bgColor={theme.colors.background.dark} /></li>
          )}
          {siteMetadata.social.twitter && (
              <li><SocialIcon url={`https://twitter.com/${siteMetadata.social.twitter}/`} fgColor={theme.colors.link} bgColor={theme.colors.background.dark} /></li>
          )}
          {siteMetadata.social.github && (
              <li><SocialIcon url={`https://github.com/${siteMetadata.social.github}/`} fgColor={theme.colors.link} bgColor={theme.colors.background.dark} /></li>
          )}
          {siteMetadata.social.youtube && (
              <li><SocialIcon url={`https://youtube.com/user/${siteMetadata.social.youtube}/`} fgColor={theme.colors.link} bgColor={theme.colors.background.dark} /></li>
          )}
          {siteMetadata.social.email && (
              <li><SocialIcon url={`mailto:${siteMetadata.social.email}/`} fgColor={theme.colors.link} bgColor={theme.colors.background.dark} /></li>
          )}
      </SocialList>
    </SocialFooter>
    <LegalFooter>
      <span>
        © {new Date().getFullYear()} Eleventy-Aught Twelve Studios. All rights reserved
      </span>
    </LegalFooter>
  </Wrapper>
);

export default Footer;

Footer.propTypes = {
    siteMetadata: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.bool,
    ]),
  };
  
  Footer.defaultProps = {
    siteMetadata: false
  };
