import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby'
import PropTypes from 'prop-types';
import { TagBlock } from 'components';

const Article = styled.article`
    width: 100%;
    max-width: 100%;
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    min-height: 8rem;
    border-radius: 10px;
    position:relative;
    background: ${props => props.theme.colors.background.dark};
    transition: all .3s ease;
    box-shadow: 0 1px 1px 0 rgba(31, 35, 46, .15);
    &:hover {
        transform: translate(0px, -2px);
        box-shadow: 0 15px 45px -10px rgba(10, 16, 34, .2);
    }
    @media only screen and (max-width : 992px) {
        width: 48%;
        max-width: 100%;
        margin: 0 0.9% 1.5rem;
        flex-direction: column;
    }
    @media only screen and (max-width : 480px) {
        width: 98%;
        max-width: 100%;
        margin: 2% 2% 1.5rem;
        flex-direction: column;
}
`;

const PostBlockContent = styled.div`
    padding: 0;
    margin: 0;
    width: 100%;
    border-radius: 10px;
    color: ${props => props.theme.colors.primary.base};
`;
const PostTitleBlock = styled.div`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 1rem;
    background: ${props => props.theme.colors.background.light};
`;

const PostTitle = styled.h2`
    margin: 0 0 10px;
    font-size: 30px;
    font-weight: 400;
    a {
      font-family: 'PT Serif', serif;
      text-decoration: none;
      color: ${props => props.theme.colors.link};
      &:hover {
        color: ${props => props.theme.colors.linkHover};
      }
    }
    
    @media only screen and (max-width : 992px) {
        margin: 0 0 5px;
        a {
          font-size: 21px;
        }
    }
    
    @media only screen and (max-width : 480px) {
        margin: 0 0 5px;
        a {
          font-size: 21px;
        }
    }
`;

const PostBlockBottom = styled.div`
    padding: 1rem;
`;

const PostExcerpt = styled.p`
    margin-top: 0;
`;

const PostInfo = styled.span`
    font-size: 12px;
    background: #d24d04;
    border: 1px solid #ff5a00;
    color: #fff;
    display: block;
    line-height: 1;
    position: absolute;
    top: -10px;
    left: -10px;
    text-shadow: #000 1px 1px 2px;
    box-shadow: 0.2em 0.2em 0.3em rgba(0, 0, 0, 0.4);
    padding: 2px;
`;

const PostBlock = ({ title, author, date, cover, excerpt, slug, id, tags }) => (
    <Article key={id}>
        <PostBlockContent>
            <PostTitleBlock>
                <PostTitle>
                    <Link to={slug}>{title}</Link>
                </PostTitle>
                <PostInfo>
                    Posted by {author} on {date}
                </PostInfo>
                { tags && (
                    <TagBlock tags={tags} />
                )}
            </PostTitleBlock>
            <PostBlockBottom>
                <PostExcerpt>{excerpt}</PostExcerpt>
            </PostBlockBottom>
        </PostBlockContent>
    </Article>
);

export default PostBlock;

PostBlock.propTypes = {
    cover: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    date: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.bool,
    ]),
    auhtor: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    excerpt: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.bool,
      ]),
    slug: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    id:  PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    tags:  PropTypes.oneOfType([PropTypes.array, PropTypes.bool]),
  };
  
  PostBlock.defaultProps = {
    cover: false,
    date: false,
    title: false,
    author: false,
    excerpt: false,
    slug: false,
    id: false,
    tags: false
  };