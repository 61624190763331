import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';

const ContentContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  color: ${props => props.theme.colors.primary.base};
  a {
    margin: 0 1rem 1rem 0;
    color: ${props => props.theme.colors.link};
    padding: 0.3rem 0.6rem;
    background: ${props => props.theme.colors.background.dark};
    border-radius: 10px;
    border: 1px solid ${props => props.theme.colors.background.light};
    &:hover {
      color: ${props => props.theme.colors.linkHover};
      background: ${props => props.theme.colors.background.medium};
      border: 1px solid ${props => props.theme.colors.linkHover};
    }
  }
`;

const PostContent = styled.div`
    margin-top: 0;
    padding:8px;
    a {
        color: ${props => props.theme.colors.link};
        &:hover {
            color: ${props => props.theme.colors.linkHover};
        }
    }
`;

const Title = styled.h1`
    margin: 0 0 10px;
    font-size: 30px;
    font-weight: 400;
    a {
      font-family: 'PT Serif', serif;
      text-decoration: none;
      color: ${props => props.theme.colors.link};
      &:hover {
        color: ${props => props.theme.colors.linkHover};
      }
    }
    
    @media only screen and (max-width : 992px) {
        margin: 0 0 5px;
        a {
          font-size: 21px;
        }
    }
    
    @media only screen and (max-width : 480px) {
        margin: 0 0 5px;
        a {
          font-size: 21px;
        }
    }
`;

const SidebarContent = ({ title, content }) => (
    <ContentContainer>
        <Title>{title}</Title>
        <PostContent dangerouslySetInnerHTML={{ __html: content }} />
    </ContentContainer>
);

export default SidebarContent;

SidebarContent.propTypes = {
    title: PropTypes.string,
    content: PropTypes.string
  };