import React from 'react';
import styled from '@emotion/styled';
import Img from 'gatsby-image'
import PropTypes from 'prop-types';

const CoverImageWrapper = styled.div`
  position: relative;
  padding: 0;
  margin: 0;
  max-height: 500px;
  background-color: ${props => props.theme.colors.background.medium};
  overflow: hidden;

  figure {
    position: relative;
    margin: 0;
  }
`;

const CoverImage = styled(Img)`
    width: 100%;
    height: 100%;
`;

const MainCoverImage = ({ cover }) => (
    <CoverImageWrapper>
        <figure>
            <CoverImage
            key={cover.childImageSharp.fluid.src}
            fluid={cover.childImageSharp.fluid}
            />
        </figure>
    </CoverImageWrapper>
);

export default MainCoverImage;

MainCoverImage.propTypes = {
    cover: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
  };
  
MainCoverImage.defaultProps = {
    cover: false,
  };