import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby'
import PropTypes from 'prop-types';
import { Footer } from 'layouts';
import logo from '../../static/logo/header-logo.png';
import SidebarContent from './SidebarContent';

const SidebarContainer = styled.aside`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 240px;
    height: 100%;
    padding: 20px 0;
    background-color: ${props => props.theme.colors.background.light};
    color: ${props => props.theme.colors.white.base};
    
    @media only screen and (max-width : 768px) {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        padding: 10px 0 0;
        box-shadow: 0 1px 1px 0 rgba(31, 35, 46, .15);
        margin-bottom: 10px;
    }
`;

const About = styled.div`
    margin: 0 0 40px;
    text-align: center;
    padding: 0 10px;
    @media only screen and (max-width : 768px) {
        text-align: center;
        max-width: 480px;
        margin: 10px auto auto;
    }
`;

const LogoLink = styled(Link)`
    width: 100px;
    height: 100px;
    margin: 0 auto 10px;
    overflow: hidden;
    img {
        width: 100%;
        height: 100%;
        transition: transform 0.35s;
        &:hover {
          transform: scale3d(0.90,0.90,1);
        }
`;

const TagLine = styled.div`
    font-family: 'PT Serif', serif;
    margin: 0 0 10px;
    position: relative;
    padding-bottom: 15px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 700;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: 0;
      display: block;
      width: 7px;
      height: 7px;
      border-radius: 100%;
    }
`;

const Sidebar = ({ siteMetadata, sideContent }) => (
    <SidebarContainer>
        <header>
            <About>
                <LogoLink to="/">
                    <img src={logo} alt="Eleventy-Aught Twelve Studios Logo" />
                </LogoLink>
                <TagLine>
                    {siteMetadata.description}
                </TagLine>
            </About>
        </header>
        <div>
        { sideContent && (
                    <SidebarContent title={sideContent.title} content={sideContent.content} />
                )}
        </div>
        <Footer siteMetadata={siteMetadata} />
    </SidebarContainer>
);

export default Sidebar;

Sidebar.propTypes = {
    siteMetadata: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.bool,
    ]),
    sideContent: PropTypes.PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      })
  };
  
  Sidebar.defaultProps = {
    siteMetadata: false,
    sideContent: []
  };