import React, { Fragment } from 'react';
import { css, ThemeProvider, Global } from '@emotion/react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import 'typeface-candal';
import 'typeface-source-code-pro';
import { StaticQuery, graphql } from 'gatsby';
import theme from '../../config/theme';
import { SEO, Sidebar } from '../components';
import { NavBar } from 'layouts';
import headroom from '../styles/headroom';

const Content = styled.div`
    padding: 20px;
    padding-left: 275px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    @media only screen and (max-width : 768px) {
        padding: 20px 10px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
    }
    @media only screen and (max-width : 480px) {
        padding: 10px;
    }
`;

const Layout = ({ children, title, sideContent }) => (
    <StaticQuery
        query={graphql`
        query SiteMetaQuery {
            site {
              siteMetadata {
                description
                author
                siteTitle
                social {
                  twitter
                  facebook
                  github
                  email
                }
              }
            }
          }
        `}
        render={data => (
            <ThemeProvider theme={theme}>
                { title
                    ? <Helmet title={`${title} | ${data.site.siteMetadata.siteTitle}`} />
                    : <Helmet title={`${data.site.siteMetadata.siteTitle}`} />
                }
                <Fragment>
                    <Global
                        styles={css`
                            html,
                            body {
                                background: ${theme.colors.background.medium};
                            }

                            .clearfix::before, .clearfix::after {
                                content: "";
                                display: table;
                            }
                              
                            .clearfix::after {
                                clear: both;
                            }
                            
                            ${headroom}
                        `}
                    />
                    <SEO title={title} />
                    <NavBar />
                    <Sidebar siteMetadata={data.site.siteMetadata} sideContent={sideContent} />
                    <Content className="clearfix">
                    {children}
                    </Content>
                </Fragment>
            </ThemeProvider>
        )}
    />
);

export default Layout;

Layout.propTypes = {
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
    title: PropTypes.string,
    sideContent: PropTypes.PropTypes.shape({
        title: PropTypes.string,
        content: PropTypes.string,
      })
  };