import React from 'react';
import styled from '@emotion/styled';
import PropTypes from 'prop-types';
import { Link } from 'gatsby'
import { MainCoverImage } from '../components';

const Wrapper = styled.article`
  width: 100%;
  max-width: 100%;
  margin-bottom: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: stretch;
  min-height: 11rem;
  border-radius: 10px;
  position:relative;
  background: ${props => props.theme.colors.background.dark};
  transition: all .3s ease;
  box-shadow: 0 1px 1px 0 rgba(31, 35, 46, .15);

  @media only screen and (max-width : 992px) {
    width: 48%;
    max-width: 100%;
    margin: 0 0.9% 1.5%;
    flex-direction: column;
  }
  @media only screen and (max-width : 480px) {
    width: 98%;
    max-width: 100%;
    margin: 2%;
    flex-direction: column;
  }
`;

const WrapperContent = styled.div`
    padding: 0;
    margin: 0;
    width: 100%;
    border-radius: 10px;
    color: ${props => props.theme.colors.primary.base};
`;

const TitleBlock = styled.div`
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 1rem;
    background: ${props => props.theme.colors.background.light};
`;

const Title = styled.h1`
    margin: 0 0 10px;
    font-size: 30px;
    font-weight: 400;
    a {
      font-family: 'PT Serif', serif;
      text-decoration: none;
      color: ${props => props.theme.colors.link};
      &:hover {
        color: ${props => props.theme.colors.linkHover};
      }
    }
    
    @media only screen and (max-width : 992px) {
        margin: 0 0 5px;
        a {
          font-size: 21px;
        }
    }
    
    @media only screen and (max-width : 480px) {
        margin: 0 0 5px;
        a {
          font-size: 21px;
        }
    }
`;

const InfoContainer = styled.div`
  font-size: 12px;
  a {
    text-decoration: none;
    color: ${props => props.theme.colors.link};
    &:hover {
      color: ${props => props.theme.colors.linkHover};
      text-decoration: underline;
    }
  }
`;

const BlockBottom = styled.div`
    text-align: ${props => (props.center ? 'center' : '')};
    padding: 1rem;
    a {
      text-decoration: none;
      color: ${props => props.theme.colors.link};
      &:hover {
        color: ${props => props.theme.colors.linkHover};
        text-decoration: underline;
      }
    }
`;

const CoverLink = styled(Link)`
  width: 30%;
  max-width: 100%;
  min-height: 11rem;
  background-size: cover;
  background-position: 50% 50%;

  @media only screen and (max-width : 992px) {
    width: 100%;
  }
  @media only screen and (max-width : 480px) {
    width: 100%;
  }
`;

const Container = ({ children, key, title, info, className, center, slug, cover }) => (
  <Wrapper key={key} className={className} center={center}>
      { cover &&
        cover.childImageSharp &&
        cover.childImageSharp.fluid && (
          <CoverLink
            to={slug}
            style={{
              backgroundImage: `url(${cover.childImageSharp.fluid.src})`,
            }}
          />
        )}
        <WrapperContent>
            <TitleBlock>
                { slug
                    ? <Title><Link to={slug}>{title}</Link></Title>
                    : <Title>{title}</Title>
                }
                {
                    info && (
                        <InfoContainer>
                            {info}
                        </InfoContainer>
                    )
                }
            </TitleBlock>
            <BlockBottom>
                {children}
            </BlockBottom>
        </WrapperContent>
  </Wrapper>
);

export default Container;

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  title: PropTypes.string,
  info: PropTypes.string,
  className: PropTypes.string,
  center: PropTypes.object,
  slug: PropTypes.string,
  cover: PropTypes.object,
  key: PropTypes.string,
};
