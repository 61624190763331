module.exports = {
    title: 'Eleventy-Aught Twelve Studios',
    description: 'Om nom nom, ooo shiny!',
    author: 'Casey Young',
    social: {
        twitter: 'EatStudios',
        facebook: 'eatstudios',
        github: '',
        email: 'inquire@eat-studios.com',
        youtube: '',
        twitch: '',
        instagram: '',
        reddit: '',

    },
    logo: '/logo/logo.png',
    siteTitle: 'Eleventy-Aught Twelve Studios',
    shortName: 'EAT Studios',
    pathPrefix: '/',
    backgroundColor: '#1b1d1f',
    themeColor: '#989eae',
    favicon: 'static/logo/logo.png',
    siteLanguage: 'en', // Language Tag on <html> element
    url: 'https://www.eat-studios.com',
    siteUrl: 'https://www.eat-studios.com'
}