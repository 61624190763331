import React from 'react';
import styled from '@emotion/styled';
import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import { kebabCase } from 'lodash';

const TagsContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  a {
    margin: 0 1rem 1rem 0;
    color: ${props => props.theme.colors.link};
    padding: 0.3rem 0.6rem;
    background: ${props => props.theme.colors.background.dark};
    border-radius: 10px;
    border: 1px solid ${props => props.theme.colors.background.light};
    &:hover {
      color: ${props => props.theme.colors.linkHover};
      background: ${props => props.theme.colors.background.medium};
      border: 1px solid ${props => props.theme.colors.linkHover};
    }
  }
`;

const TagCloud = ({ list, showNum }) => (
    <TagsContainer>
    {list &&
      list.map(tag => {
        if(showNum) {
          const lowerTag = tag.fieldValue.toLowerCase();
          return (
            <Link key={tag.fieldValue} to={`/tags/${kebabCase(lowerTag)}`}>
              <span>{tag.fieldValue}</span><span>({tag.totalCount})</span>
            </Link>
          );
        }else {
          const lowerTag = tag.toLowerCase()
          return (
            <Link key={tag} to={`/tags/${kebabCase(lowerTag)}`}>
              <span>{tag}</span>
            </Link>
          );
        }
      })}
  </TagsContainer>
);

export default TagCloud;

TagCloud.propTypes = {
    list: PropTypes.array,
    showNum: PropTypes.bool,
  };